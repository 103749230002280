import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import PlaylistSong from "../PlaylistSong";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

const Container = styled.div`
    margin: 8px;
    border: 1px solid lightgrey;
    border-radius: 2px;
`;
const Title = styled.h3`
    padding: 8px;
`;
const SongList = styled.div`
    padding: 8px;
`;

const PlaylistSongList = (props) => {
    const { songs } = props; // TODO, don't get these from props, but from api
    const songsWithoutPlayingSong =
        songs && songs.length > 1 ? songs.slice(1) : [];

    return (
        <DragDropContext
            onDragEnd={(result) => {}} // TODO update the state of the songs
        >
            <Container>
                <Title>Songs Queued up</Title>
                <Droppable droppableId="song-list-droppable-id">
                    {(provided) => (
                        <SongList
                            innerRef={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            {songsWithoutPlayingSong.map((song, index) => (
                                <PlaylistSong
                                    key={song.playlistSongId}
                                    song={song}
                                    index={index}
                                />
                            ))}
                            {provided.placeholder}
                        </SongList>
                    )}
                </Droppable>
            </Container>
        </DragDropContext>
    );
};

PlaylistSongList.propTypes = {
    songs: PropTypes.array.isRequired,
};

export default PlaylistSongList;
