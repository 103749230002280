import React from "react";
import DisplayableSong from "../../DisplayableSong";
import { Draggable } from "react-beautiful-dnd";

const PlaylistSong = (props) => {
    const { song, index } = props;
    const { songId, displayTitle } = song;

    return (
        <Draggable draggableId={songId} index={index}>
            {(provided) => (
                <DisplayableSong
                    song={song}
                    draggableProps={provided.draggableProps}
                    dragHandleProps={provided.dragHandleProps}
                    innerRef={provided.innerRef}
                    shouldRemoveOnClick
                >
                    {displayTitle}
                </DisplayableSong>
            )}
        </Draggable>
    );
};

export default PlaylistSong;
