import React, { useState, useContext } from "react";
import SessionContext from "../SessionContext";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import Media from "react-bootstrap/Media";
import Button from "react-bootstrap/Button";
import {
    getDisplayImage,
    getTitleText,
    getDisplayTextIntro,
    getDisplayText,
} from "../../util/song-formatter";

const Container = styled.div`
    border: 1px solid lightgrey;
    border-radius: 2px;
    padding: 2px;

    p {
        color: gray;
    }
`;

const MediaContainer = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
    align-content: center;
    overflow: hidden;
    padding: 4px;
`;

const DetailsContainer = styled.div`
    flex: 1 0 30%;
    padding: 2px;
`;

const AddContainer = styled.div`
    align-self: center;
    flex: 0 1 auto;
`;

const PlaylistSong = (props) => {
    const {
        song,
        draggableProps,
        dragHandleProps,
        innerRef,
        shouldRemoveOnClick,
    } = props;
    const [sessionContext] = useContext(SessionContext);

    if (!song) throw new Error("song is required");
    const { songId, title } = song;
    const imageUrl = getDisplayImage(song);
    const titleText = getTitleText(song);
    const displayTitle = getDisplayTextIntro(song);
    const displayText = getDisplayText(song);

    const [songAddedState, setSongAddedState] = useState({
        hasSongBeenAdded: false,
    });

    const addSong = () => {
        setSongAddedState({ hasSongBeenAdded: true });
        sessionContext.addSong({ songId, singer: "bootsie" });
    };

    const buttonText = songAddedState.hasSongBeenAdded
        ? "✔"
        : shouldRemoveOnClick
        ? "-"
        : "+";

    const buttonVariant = songAddedState.hasSongBeenAdded
        ? "success"
        : shouldRemoveOnClick
        ? "danger"
        : "primary";

    return (
        <Container {...draggableProps} {...dragHandleProps} innerRef={innerRef}>
            <Media>
                <img
                    width={120}
                    height={120}
                    className="song-image"
                    src={imageUrl}
                    alt={`${title} Karaoke Song Image`}
                />
                <Media.Body>
                    <MediaContainer>
                        <DetailsContainer>
                            <h4>{titleText}</h4>
                            <p>{displayTitle}</p>
                            <h5>{displayText}</h5>
                        </DetailsContainer>
                        <AddContainer>
                            <Button
                                as="input"
                                type="button"
                                value={buttonText}
                                size="lg"
                                onClick={() =>
                                    shouldRemoveOnClick
                                        ? sessionContext.removeSong(song)
                                        : addSong()
                                }
                                disabled={songAddedState.hasSongBeenAdded}
                                variant={buttonVariant}
                            />{" "}
                        </AddContainer>
                    </MediaContainer>
                </Media.Body>
            </Media>
        </Container>
    );
};

PlaylistSong.propTypes = {
    song: PropTypes.object.isRequired,
    onSongAdded: PropTypes.func,
    shouldRemoveOnClick: PropTypes.bool,
};

export default PlaylistSong;
