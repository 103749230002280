export const updateUrlForSessionId = (sessionId) => {
    console.log("shared session id setting", sessionId);
    if (!sessionId) return;
    const newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        "?share=" +
        sessionId;
    window.history.pushState({ path: newurl }, "", newurl);
};
