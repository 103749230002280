import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import DisplayableSong from "../DisplayableSong";

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const SongResults = (props) => {
    const { songs, onSongAdded } = props;

    return (
        <Container>
            {songs &&
                songs.map((song) => (
                    <DisplayableSong
                        key={song.songId}
                        song={song}
                        onSongAdded={onSongAdded}
                    />
                ))}
        </Container>
    );
};

SongResults.propTypes = {
    songs: PropTypes.array.isRequired,
    onSongAdded: PropTypes.func,
};

export default SongResults;
