import React, { useState, useEffect, useContext } from "react";
import styled from "@emotion/styled";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Categoryies from "../Categories";
import SongResults from "../SongResults";
import { getSongsByCategoryType } from "./song-category-retriever";
import SessionContext from "../SessionContext";

const Container = styled.div``;

const SongBrowser = ({ sessionId }) => {
    const [sessionContext] = useContext(SessionContext);

    const [keyCategoryState, setKeyCategoryState] = useState({
        key: "popular",
    });
    const [tabState, setTabState] = useState();

    const onCardSelected = (category) => {
        console.log("setting category", category);
        setKeyCategoryState({ ...keyCategoryState, category });
    };

    const onSongAdded = (songId) => {
        sessionContext.addSong({
            songId,
            singer: "tootsie",
        });
    };

    useEffect(() => {
        const setSongResults = async () => {
            const { key, category } = keyCategoryState;
            if (category) {
                const songs = await getSongsByCategoryType(key, category);
                console.log("songs", songs);
                setTabState(
                    <SongResults songs={songs} onSongAdded={onSongAdded} />
                );
                return;
            }
            setTabState(
                <Categoryies
                    key={key}
                    categoryType={key}
                    onCardSelected={onCardSelected}
                />
            );
        };
        setSongResults();
    }, [keyCategoryState.key, keyCategoryState.category]);

    return (
        <Container>
            <Tabs
                id="categories-tab"
                activeKey={keyCategoryState.key}
                onSelect={(k) => setKeyCategoryState({ key: k })}
            >
                <Tab eventKey="popular" title="Popular">
                    {tabState}
                </Tab>
                <Tab eventKey="artist" title="By Artist">
                    {tabState}
                </Tab>
                <Tab eventKey="title" title="By Title">
                    {tabState}
                </Tab>
                <Tab eventKey="year" title="By Year">
                    {tabState}
                </Tab>
            </Tabs>
        </Container>
    );
};

export default SongBrowser;
