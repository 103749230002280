import React, { useContext } from "react";
import styled from "@emotion/styled";
import CurrentlyPlayingSong from "./CurrentlyPlayingSong";
import PlaylistSongList from "./PlaylistSongList";
import SessionContext from "../SessionContext";

// Spinning the http server and the websocket server.

const Playlist = () => {
    const [sessionContext] = useContext(SessionContext);

    const Container = styled.div`
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: auto;
        overflow: auto;
        height: 100%;
        width: 100%;
    `;

    const CurrentlyPlayingContainer = styled.div`
        flex: 1 0 50%;
        height: 100%;
        width: 100%;
    `;
    const PlaylistContainer = styled.div`
        flex: 1 0 50%;
        height: 100%;
        width: 100%;
    `;

    console.log("session Context", sessionContext);
    const { playlist } = sessionContext;

    return (
        <Container>
            <CurrentlyPlayingContainer>
                <CurrentlyPlayingSong
                    song={playlist && playlist.length > 0 ? playlist[0] : {}}
                />
            </CurrentlyPlayingContainer>
            <PlaylistContainer>
                <PlaylistSongList
                    key="playlist-song-list"
                    songs={playlist || []}
                />
            </PlaylistContainer>
        </Container>
    );
};

export default Playlist;
