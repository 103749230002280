import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { buildCategoryCards } from "./category-card-builder";

const Container = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

const Categories = ({ categoryType, onCardSelected }) => {
    const [categoryState, setCategoryState] = useState({
        categoryType: categoryType,
        cards: <div />,
    });

    useEffect(() => {
        const cards = buildCategoryCards(
            categoryState.categoryType,
            onCardSelected
        );
        setCategoryState({ ...categoryState, cards });
    }, [categoryState.categoryType]);

    return <Container>{categoryState.cards}</Container>;
};

Categories.propTypes = {
    categoryType: PropTypes.string.isRequired,
    onCardSelected: PropTypes.func,
};

export default Categories;
