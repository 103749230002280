import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ backgroundColor }) => backgroundColor || "black"};
    color: ${({ textColor }) => textColor || "white"};
    font-size: ${({ fontSize }) => fontSize / 2}px;
    font-stretch: expanded;
    font-family: "${({ fontFamily }) => fontFamily || "Arial"}";
    font-weight: "${({ fontWeight }) => fontWeight || "normal"}";
    
    margin: 10px;
    padding: 10px;
    height: 65px;
    width: 65px;
    @media (min-width: 600px) {
        margin: 20px;
        height: 140px;
        width: 140px;
        font-size: ${({ fontSize }) => fontSize}px;
    }
`;

const CategoryCard = ({
    text,
    backgroundColor,
    textColor,
    fontFamily,
    fontWeight,
    onClick,
    multiplicationFactor,
}) => {
    if (!text) return <div />;
    multiplicationFactor = multiplicationFactor || 1;
    const textLength = text.length;
    const fontSize = (multiplicationFactor * 190) / textLength;
    return (
        <a href="#" onClick={onClick}>
            <Container
                fontSize={fontSize}
                backgroundColor={backgroundColor}
                textColor={textColor}
                fontFamily={fontFamily}
                fontWeight={fontWeight}
            >
                {text}
            </Container>
        </a>
    );
};

CategoryCard.propTypes = {
    text: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string,
    textColor: PropTypes.string,
    fontFamily: PropTypes.string,
    fontWeight: PropTypes.string,
    onClick: PropTypes.func,
    multiplicationFactor: PropTypes.number,
};

export default CategoryCard;
