import styled from "@emotion/styled";

export const PlayerContainerStyle = styled.div`
    padding: 20px;

    .aspect-ratio--16x9 {
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
        position: relative;
    }

    .aspect-ratio__inner-wrapper {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .youtube-video {
        width: 100%;
        height: 100%;
    }

    iframe {
        height: 100%;
        width: 100%;
    }
`;
