import axios from "axios";

const baseUrl = process.env.API_URL;

const get = async (relativeUrl) => {
    const response = await axios(baseUrl + relativeUrl);
    if (response.status !== 200) {
        throw new Error(`unsuccessful GET ${await response.data}`);
    }
    return response.data;
};

const put = async (relativeUrl, body) => {
    const content = body && JSON.stringify(body);
    const response = await axios.put(baseUrl + relativeUrl, content);
    if (response.status !== 201 && response.status !== 204) {
        throw new Error(`unsuccessful PUT ${await response.data}`);
    }
    return response.data;
};

const deleteMethod = async (relativeUrl) => {
    const response = await axios.delete(baseUrl + relativeUrl);
    if (response.status !== 200 && response.status !== 204) {
        throw new Error(`unsuccessful DELETE ${await response.data}`);
    }
    return response.data;
};

const postAndGet = async (relativeUrl, body) => {
    const content = body && JSON.stringify(body);
    const response = await axios.post(baseUrl + relativeUrl, content);
    if (response.status !== 201 && response.status !== 204) {
        throw new Error(`unsuccessful POST ${await response.data}`);
    }
    return response.data;
};

let categoryCache; // TODO, page on the API
export const getCategories = async () => {
    if (categoryCache) return categoryCache;
    const { items } = await get("/categories");
    categoryCache = items;
    return categoryCache;
};

const categorySongCache = {}; // TODO, page on the API
export const getSongsByCategory = async (category, page, pageSize) => {
    if (categorySongCache[category]) return categorySongCache[category];
    const categories = await getCategories();
    if (!categories.some((c) => c === category)) {
        throw new Error(
            `Category "${category}" not found in "${categoryCache.join(",")}"`
        );
    }
    const { items } = await get(`/categories/${category}/playlistsongs`);
    categorySongCache[category] = items;
    // TODO paginate
    return categorySongCache[category];
};

export const createNewSession = async () => {
    const { sessionId } = await postAndGet("/sessions");
    return sessionId;
};

export const addSongToSession = async (sessionId, songAndSinger) => {
    await postAndGet(`/sessions/${sessionId}/song`, songAndSinger);
};

export const getSessionData = async (sessionId) => {
    try {
        return await get(`/sessions/${sessionId}`);
    } catch (error) {
        console.warn("attempted to get non-existant session", sessionId);
        return undefined;
    }
};

export const updateSessionData = async (sessionId, data) => {
    return await put(`/sessions/${sessionId}`, data);
};

export const removeSessionsPlayingSong = async (sessionId, data) => {
    return await deleteMethod(`/sessions/${sessionId}/song`);
};

export const getVideoBySongWithId = async (song) => {
    const { songId } = song;
    return await get(`/songs/${songId}`);
};
