let socket;

export const setupSocketForSession = (sessionId, onMessageReceived) => {
    // eslint-disable-next-line no-undef
    socket = new WebSocket(
        "wss://b2gx8tfba9.execute-api.us-east-1.amazonaws.com/dev" // TODO, more than a lambda? at least env var
    );

    // Connection opened
    socket.addEventListener("open", function () {
        try {
            socket.send(JSON.stringify({ sessionId }));
        } catch (error) {
            console.error("websocket send error on open", error);
        }
    });

    // Listen for messages
    socket.addEventListener("message", onMessageReceived);
};

export const sendMessage = (message) => {
    try {
        socket.send(JSON.stringify({ type: "message", message }));
    } catch (error) {
        console.error("websocket send error on send", error);
    }
};
