import styled from "@emotion/styled";

export const PlayerPageStyle = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin: 10px;
    height: 92vh;
    width: 100vw;

    @media (min-width: 600px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
    }
`;

export const BrowserStyle = styled.div`
    flex-shrink: 0;
    flex-grow: 0;
    height: 300px;
    overflow: auto;
    padding: 5px;
    padding-left: 0px;
    padding-right: 10px;
    padding-top: 20px;

    @media (min-width: 600px) {
        grid-area: 1 / 1 / 3 / 2;
        height: 100%;
    }
`;

export const PlayerStyle = styled.div`
    flex-shrink: 1;
    flex-grow; 0;

    @media (min-width: 600px) {
        grid-area: 1 / 2 / 2 / 2;
    }
`;

export const PlaylistStyle = styled.div`
    flex-shrink: 4;
    flex-grow: 0;
    overflow: scroll;
    height: 250px;

    @media (min-width: 600px) {
        grid-area: 1 / 2 / 3 / 2;
        padding-top: 56%;
        margin: 10px;
        height: 100%;
    }
`;
