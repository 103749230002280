import { getSongsByCategory } from "../../clients/api-client";

export const getSongsByCategoryType = async (categoryType, category) => {
    switch (categoryType) {
        case "popular":
            return await getSongsByCategory(category);
        default:
            return [];
    }
};
