import React, { useState } from "react";
import PropTypes from "prop-types";
import ReactPlayer from "react-player";
import { PlayerContainerStyle } from "../player-container-style";

const YoutubePlayer = ({ videoId, onEnded }) => {
    const [playingState, setPlayingState] = useState({ isPlaying: false });
    const getUrl = () => {
        return `https://www.youtube.com/watch?v=${videoId}`;
    };

    return (
        <iframe
            width="480"
            height="270"
            src="//www.youtube.com/embed/_Bkous7AiDM\"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture\"
            allowFullscreen
        />
    );
    // return (
    //     <PlayerContainerStyle>
    //         <div className="aspect-ratio--16x9">
    //             <div className="aspect-ratio__inner-wrapper">
    //                 <ReactPlayer
    //                     className="youtube-video"
    //                     url={getUrl()}
    //                     onEnded={onEnded}
    //                     controls
    //                     height="100%"
    //                     width="100%"
    //                     playing={videoId && playingState.isPlaying}
    //                     onPause={() => setPlayingState({ isPlaying: false })}
    //                     onPlay={() => setPlayingState({ isPlaying: true })}
    //                 />
    //             </div>
    //         </div>
    //     </PlayerContainerStyle>
    // );
};

YoutubePlayer.propTypes = {
    videoId: PropTypes.string,
    onEnded: PropTypes.func.isRequired,
};

export default YoutubePlayer;
