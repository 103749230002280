import {
    addSongToSession,
    getSessionData,
    updateSessionData,
    removeSessionsPlayingSong,
} from "../clients/api-client";
import { setupSocketForSession } from "../clients/web-socket-client";

const reRetrieveSessionState = async (sessionId, setSessionState) => {
    if (!sessionId) throw new Error("sessionId not provided");
    if (!setSessionState) throw new Error("setSessionState not provided");
    const sessionData = await getSessionData(sessionId);
    const sessionState = {
        ...getSessionStateDefault(sessionId, setSessionState),
        ...sessionData,
    };
    setSessionState(sessionState);
};

const addSong = async (sessionId, setSessionState, songIdAndSinger) => {
    await addSongToSession(sessionId, songIdAndSinger);
    await reRetrieveSessionState(sessionId, setSessionState);
};

const removeSong = async (sessionId, setSessionState, playlistSong) => {
    if (!playlistSong) throw new Error("playlistSong is required");
    const sessionData = await getSessionData(sessionId);
    const newSessionData = {
        ...sessionData,
        playlist: sessionData.playlist.filter(
            (song) => song.playlistSongId !== playlistSong.playlistSongId
        ),
    };
    await updateSessionData(sessionId, newSessionData);
    await reRetrieveSessionState(sessionId, setSessionState);
};
const removePlayingSong = async (sessionId, setSessionState) => {
    await removeSessionsPlayingSong(sessionId);
    await reRetrieveSessionState(sessionId, setSessionState);
};

export const getSessionStateDefault = (sessionId, setSessionState) => {
    if (!sessionId) throw new Error("default sessionId not provided");
    if (!setSessionState)
        throw new Error("default setSessionState not provided");
    const onMessageReceived = async (event) => {
        console.log("received");
        console.log("Message from server ", event);
        const { data } = event;
        if (data === "SongAdded") {
            await reRetrieveSessionState(sessionId, setSessionState);
        }
    };
    setupSocketForSession(sessionId, onMessageReceived);
    return {
        sessionId: sessionId,
        playlistSongs: [],
        addSong: (songIdAndSinger) =>
            addSong(sessionId, setSessionState, songIdAndSinger),
        removeSong: (playlistSong) =>
            removeSong(sessionId, setSessionState, playlistSong),
        removePlayingSong: () => removePlayingSong(sessionId, setSessionState),
    };
};
