export const getDisplayImage = (song, isHighQuality) => {
    const { images } = song;
    const index = isHighQuality
        ? images && images.length && images.length - 1
        : 0;
    return images && images.length > 0 ? images[index].url : ""; // TODO, not found image
};

export const getTitleText = (song) => {
    const { title, year } = song;
    return year ? `${title} (${year})` : title;
};

export const getDisplayTextIntro = (song) => {
    const { artist } = song;
    return artist ? "in the style of:" : "made famous by:";
};

export const getDisplayText = (song) => {
    const { artist, show } = song;
    return artist && show
        ? `${artist} (from ${show})`
        : artist || show || "unknown";
};
