import React, { useState, useEffect } from "react";
import SessionContext from "../SessionContext";
import YoutubePlayer from "../Players/YoutubePlayer";
import Playlist from "../Playlist";
import SongBrowser from "../SongBrowser";
import { getSessionStateDefault } from "../../util/session-state-default";
import { createNewSession, getSessionData } from "../../clients/api-client";
import { updateUrlForSessionId } from "../../util/session-url-updater";
import { getVideoBySongWithId } from "../../clients/api-client";
import {
    PlayerPageStyle,
    PlayerStyle,
    PlaylistStyle,
    BrowserStyle,
} from "./player-style";

const PlayerPageTemplate = () => {
    const [sessionState, setSessionState] = useState({});
    const defaultVideoId = "TuC_-7vy_F0"; // TODO, this should be a tutorial video
    const [currentlyPlayingVideo, setCurrentlyPlayingVideo] = useState({
        videoId: defaultVideoId,
    });

    const setSessionIdQueryString = () => {
        const { sessionId } = sessionState;
        updateUrlForSessionId(sessionId);
    };

    useEffect(() => {
        const { sessionId } = sessionState;
        setSessionIdQueryString();
        if (sessionId) return; // Already has a session
        const setSession = async () => {
            // Check if it is in the query first
            const urlParams = new URLSearchParams(window.location.search);
            if (urlParams.has("share")) {
                const urlSessionId = urlParams.get("share");
                const sessionData = await getSessionData(urlSessionId);
                if (sessionData) {
                    setSessionState({
                        ...getSessionStateDefault(
                            urlSessionId,
                            setSessionState
                        ),
                        ...sessionData,
                    });
                    return;
                }
            }
            // Otherwise create a new session
            const sessionId = await createNewSession();
            console.log("sessionId", sessionId);
            setSessionState({
                ...getSessionStateDefault(sessionId, setSessionState),
            });
        };
        setSession();
    }, [sessionState.sessionId]);

    useEffect(() => {
        const setVideoId = async () => {
            if (
                !sessionState ||
                !sessionState.playlist ||
                !sessionState.playlist.length > 0
            ) {
                return defaultVideoId; // TODO, should this just be blank if songs are done?
            }
            const firstSong = sessionState.playlist[0];
            const video = await getVideoBySongWithId(firstSong);
            setCurrentlyPlayingVideo(video);
        };
        setVideoId();
    }, [sessionState.playlist]);

    return (
        <SessionContext.Provider value={[sessionState, setSessionState]}>
            <PlayerPageStyle>
                <BrowserStyle>
                    <SongBrowser sessionId={sessionState.sessionId} />
                </BrowserStyle>
                <PlayerStyle>
                    <YoutubePlayer
                        videoId={currentlyPlayingVideo.videoId}
                        onEnded={() => {
                            sessionState.removePlayingSong();
                        }}
                    />
                </PlayerStyle>
                <PlaylistStyle>
                    <Playlist />
                </PlaylistStyle>
            </PlayerPageStyle>
        </SessionContext.Provider>
    );
};

export default PlayerPageTemplate;
