import React from "react";
import CategoryCard from "./CategoryCard";

export const buildCategoryCards = (categoryType, onCardSelected) => {
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("").map((a) => a);
    const years = [
        "1950s",
        "1960s",
        "1970s",
        "1980s",
        "1990s",
        "2000s",
        "2010s",
        "2020s",
    ];

    switch (categoryType) {
        case "artist":
        case "title":
            return alphabet.map((a) => (
                <CategoryCard
                    key={a}
                    text={a}
                    onClick={() => onCardSelected(a)}
                    textColor="LightBlue"
                    multiplicationFactor={0.7}
                />
            ));
        case "year":
            return years.map((y) => (
                <CategoryCard
                    key={y}
                    text={y}
                    onClick={() => onCardSelected(y)}
                    textColor="White"
                />
            ));
        case "popular":
            return [
                <CategoryCard
                    key="Disney"
                    text="Disney"
                    onClick={() => onCardSelected("Disney")}
                    textColor="White"
                    backgroundColor="DarkBlue"
                    fontFamily="WaltographRegular"
                    multiplicationFactor={1.3}
                />,
                <CategoryCard
                    key="Dreamworks"
                    text="DreamWorks"
                    onClick={() => onCardSelected("Dreamworks")}
                    textColor="White"
                    backgroundColor="Blue"
                    fontFamily="FerrumExtracondensed"
                    fontWeight="bold"
                    multiplicationFactor={1.5}
                />,
                <CategoryCard
                    key="Broadway"
                    text="Musicals"
                    onClick={() => onCardSelected("Broadway")}
                    textColor="Yellow"
                    backgroundColor="Black"
                    fontFamily="MusicalRegular"
                />,
                <CategoryCard
                    key="Movies"
                    text="MOVIES 📽"
                    onClick={() => onCardSelected("Movies")}
                    textColor="orange"
                    backgroundColor="purple"
                    fontFamily="KiriFontNormal"
                />,
            ];
        default:
            return <div />;
    }
};
