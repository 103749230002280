import React, { useContext } from "react";
import SessionContext from "../../SessionContext";
import styled from "@emotion/styled";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import {
    getTitleText,
    getDisplayTextIntro,
    getDisplayText,
} from "../../../util/song-formatter";

const HeaderContainer = styled.div`
    width: 100%;
    display: flex;
    flex-flow: row no-wrap;
    justify-content: space-between;
`;

const PlaylistSong = (props) => {
    const { song } = props;
    const [sessionContext] = useContext(SessionContext);

    if (!song) {
        return <div />;
    }
    const title = getTitleText(song);
    const textIntro = getDisplayTextIntro(song);
    const text = getDisplayText(song);

    return (
        <Card style={{ width: "18rem" }}>
            <Card.Header>
                <HeaderContainer>
                    <span>Now Playing</span>
                    <Button
                        variant="danger"
                        onClick={() => sessionContext.removePlayingSong()}
                    >
                        —
                    </Button>
                </HeaderContainer>
            </Card.Header>
            <Card.Body>
                <Card.Title>{title}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                    {textIntro}
                </Card.Subtitle>
                <Card.Text>{text}</Card.Text>
            </Card.Body>
        </Card>
    );
};

export default PlaylistSong;
